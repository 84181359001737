<template>
  <div
    :class="{
      'border-eggshell before:bg-eggshell':
        status === 'complete' || status === 'ready-build',
      'border-green before:bg-green':
        status === 'ready' || status === 'ready-move-in',
      'border-orange before:bg-orange': status === 'under-construction',
      'bg-red border-red text-white before:bg-white': status === 'sold',
      'border-yellow before:bg-yellow': status === 'under-offer',
      'border-coral-reef before:bg-coral-reef': status === 'off-plan',
    }"
    class="text-xxs flex items-center gap-1 rounded-3xl border-2 px-1.5 py-0.5 font-medium text-gray-900 before:block before:size-2 before:rounded-full before:content-['']"
  >
    {{ statusLabel }}
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  status?: string
}>()

const { fetchPropertyDevStatusEntries } = usePropertyStatusEntriesStore()
const { propertyDevStatusEntries } = storeToRefs(
  usePropertyStatusEntriesStore(),
)
await fetchPropertyDevStatusEntries()

const statusLabel = computed<string | undefined>(
  () =>
    propertyDevStatusEntries.value?.find(
      (entry) => entry.value === props.status,
    )?.name || '',
)
</script>
