<template>
  <component
    :is="component"
    :to="formatLink(link?.cached_url)"
    :class="{
      'lg:border-2 lg:border-solid lg:border-gray-200': showCtas,
      'shadow-md shadow-gray-200': showShadow,
      'ease-out-expo transform transition-transform duration-700 hover:-translate-y-2':
        component !== 'div',
    }"
    class="relative flex flex-col overflow-clip rounded-xl bg-white"
  >
    <figure
      v-if="image?.filename && showImage"
      class="relative aspect-[396/264] max-h-[16.5rem] w-full bg-gray-300"
    >
      <NuxtImg
        :src="image.filename"
        :alt="image.alt"
        width="396"
        class="size-full object-cover"
      />

      <figcaption
        v-if="valueProposition"
        :style="{
          '--bg-color':
            communityContent?.backgroundDark.color ?? colors.primary[500],
          '--text-color': communityContent?.contentDark.color ?? colors.white,
        }"
        class="text-xxs absolute left-0 top-0 rounded-br-lg bg-[--bg-color] px-3 py-1.5 font-medium text-[--text-color]"
      >
        {{ valueProposition }}
      </figcaption>
    </figure>

    <figure
      v-if="showImage && badge?.filename"
      class="absolute right-5 top-0 w-16"
    >
      <NuxtImg :src="badge.filename" :alt="badge.alt" />
    </figure>

    <div
      :class="{
        'py-6 lg:px-6': showCtas,
        'p-3': !showCtas,
      }"
      class="flex flex-grow flex-col items-start gap-y-2 text-gray-900"
    >
      <PropertyStatusLabel v-if="status" :status="status" />

      <div v-if="formattedPrice" class="text-xl font-bold">
        {{ formattedPrice }}<sup v-if="showPriceDisclaimer">*</sup>
      </div>

      <div
        v-if="vitals.length"
        class="flex flex-grow flex-wrap items-start gap-3"
      >
        <div
          v-for="(vital, vitalIndex) in vitals"
          :key="vitalIndex"
          class="flex items-center gap-1 text-sm"
        >
          <UiIcon :icon="vital.icon" size="md" class="text-primary-800" />

          <div v-if="vital.label" class="font-medium">
            {{ vital.label }}
          </div>

          <div v-if="vital.append">
            {{ vital.append }}
          </div>
        </div>
      </div>

      <div
        v-if="address || builders?.length"
        class="flex w-full flex-wrap items-center justify-between"
      >
        <address
          v-if="address"
          class="text-secondary max-w-[200px] text-xs not-italic leading-tight"
        >
          {{ address }}
        </address>

        <div
          v-for="builder in builders"
          :key="builder._uid"
          class="flex flex-wrap gap-2"
        >
          <figure
            v-if="builder.logo?.filename"
            class="flex h-16 w-20 items-center justify-center"
          >
            <NuxtImg :src="builder.logo.filename" :alt="builder.logo.alt" />
          </figure>
        </div>
      </div>

      <div v-if="showCtas" class="mt-6 w-full border-t-2 border-gray-100 pt-6">
        <div class="flex flex-col gap-3">
          <UiButton
            :link="bookInspectionLink"
            :full-width="true"
            :custom-color-theme="
              communityContent
                ? getCommunityThemeButtonCSSVars(
                    'outline',
                    'dark',
                    communityContent,
                  )
                : undefined
            "
            variant="outline"
            theme="dark"
            label="Book an inspection"
            left-icon="calendar"
            size="md"
          />

          <UiButton
            :link="`tel:${phoneNumber}`"
            :full-width="true"
            :custom-color-theme="
              communityContent
                ? getCommunityThemeButtonCSSVars(
                    'fill',
                    'dark',
                    communityContent,
                  )
                : undefined
            "
            variant="fill"
            theme="dark"
            label="Talk to us"
            left-icon="phone"
            size="md"
          />
        </div>
      </div>
    </div>
  </component>
</template>

<script setup lang="ts">
import colors from '#tailwind-config/theme/colors'
import type { PropertyCardProps } from '@/types/storyblok'
import type { Icon } from '@/components/ui/UiIcon.vue'
import { formatLink } from '@/utils/link-utils'
import { getCommunityThemeButtonCSSVars } from '@/utils/theme-utils'

const props = withDefaults(defineProps<PropertyCardProps>(), {
  link: undefined,
  image: undefined,
  status: undefined,
  valueProposition: undefined,
  bedrooms: undefined,
  bathrooms: undefined,
  carSpaces: undefined,
  propertyType: undefined,
  width: undefined,
  depth: undefined,
  landSize: undefined,
  facing: undefined,
  price: undefined,
  address: undefined,
  builders: undefined,
  showCtas: false,
  showShadow: true,
  bookInspectionLink: undefined,
  agents: undefined,
  phone: undefined,
  showImage: true,
  badge: undefined,
  showPriceDisclaimer: false,
})

const { phone: corporatePhoneNumber } = await useConfigStore().asyncConfig()

const component = computed(() =>
  props.link?.cached_url ? resolveComponent('NuxtLink') : 'div',
)

const { communityContent } = storeToRefs(useCurrentStoryStore())

const phoneNumber = computed(() => {
  if (props.phone) return props.phone

  const agentPhone = props.agents?.find((agent) => agent.content.phone)?.content
    .phone
  return agentPhone ?? corporatePhoneNumber.value
})

const formattedPrice = computed(() =>
  props.price
    ? new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(props.price)
    : undefined,
)

const vitals = computed(() => {
  const value: {
    icon: Icon
    label: string | number | undefined
    append?: string
  }[] = []

  if (props.bedrooms) {
    value.push({ icon: 'bedroom', label: props.bedrooms })
  }

  if (props.bathrooms) {
    value.push({ icon: 'bathroom', label: props.bathrooms })
  }

  if (props.carSpaces) {
    value.push({ icon: 'garage', label: props.carSpaces })
  }

  if (props.landSize) {
    value.push({
      icon: 'lot-size',
      label: roundDecimal(props.landSize),
      append: 'm²',
    })
  }

  if (props.propertyType === 'Land') {
    if (props.width) {
      value.push({
        icon: 'lot-width',
        label: roundDecimal(props.width, 1),
        append: 'm',
      })
    }

    if (props.depth) {
      value.push({
        icon: 'lot-depth',
        label: roundDecimal(props.depth, 1),
        append: 'm',
      })
    }
  }

  if (props.facing) {
    value.push({
      icon: 'facing',
      label: props.facing,
      append: 'facing',
    })
  }

  return value
})
</script>
